import { ListItemProps } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { NotInterested, PeopleAlt } from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import MailIcon from '@material-ui/icons/Mail';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BarChartIcon from '@mui/icons-material/BarChart';
import FolderIcon from '@mui/icons-material/Folder';
import ViewAgenda from '@mui/icons-material/ViewAgenda';
import SendIcon from '@mui/icons-material/Send';
import CategoryIcon from '@mui/icons-material/Category';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ExtendedTheme } from '../theme';

const useStyles = makeStyles((theme: ExtendedTheme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: theme.drawerWidth,
      width: `calc(100% - ${theme.drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: theme.drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: theme.drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  })
);

const menu = [
    { label: 'Dashboard', icon: <DashboardIcon />, route: '/' },
    { label: '신규 회원 현황', icon: <EqualizerIcon />, route: '/user-stats' },
    { label: '사용자 유형 현황', icon: <EqualizerIcon />, route: '/usertype-stats' },
    { label: '페이지뷰 현황', icon: <EqualizerIcon />, route: '/view-stats' },
    { label: '뉴스레터 구독 현황', icon: <BarChartIcon />, route: '/newsletter-stats' },
    { label: '회원 리스트', icon: <PeopleAlt />, route: '/users' },

  // { label: 'Temp', icon: <NotInterested />, route: '/temp' },
];
const managementList = [
    { label: '뉴스레터 관리', icon: <MailIcon />, route: '/newsletters' },
    { label: '큐레이션 관리', icon: <MenuBookIcon />, route: '/curations' },
    { label: '배너 관리 (슬림)', icon: <ViewAgenda />, route: '/banners' },
    { label: '배너 관리 (메인)', icon: <ViewAgenda />, route: '/main-banners' },
    { label: '배너 관리 (뉴스레터)', icon: <ViewAgenda />, route: '/newsletter-banners' },
    { label: '배너 관리 (출석이벤트)', icon: <ViewAgenda />, route: '/attendance-banners' },
    { label: '스티커 관리', icon: <ViewAgenda />, route: '/stickers' },
    { label: '카테고리 관리 (아티클)', icon: <CategoryIcon />, route: '/categories' },
    { label: '카테고리 관리 (큐레이션)', icon: <CategoryIcon />, route: '/curation-categories' },
    { label: '카테고리 관리 (스티커)', icon: <CategoryIcon />, route: '/sticker-categories' },
];

const etcList = [
    { label: '푸시알림 발송 관리', icon: <SendIcon />, route: '/push-notifications' }
];
const ListItemLink = (props: ListItemProps<'a', { button?: true }>) => {
  return <ListItem button component="a" {...props} />;
};
export const LeftDrawer = (props: any) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const classes = useStyles();
  const history = useHistory();

  const handleSelect = (index: number, route: string) => {
    setSelectedIndex(index);
    history.push(route);
  };
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: props.isOpen,
        [classes.drawerClose]: !props.isOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: props.isOpen,
          [classes.drawerClose]: !props.isOpen,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={props.onToggleClick}>{<ChevronLeftIcon />}</IconButton>
      </div>
      <Divider />
      <List>
        {menu.map((item, index) => (
          <ListItemLink key={item.label} onClick={() => handleSelect(index, item.route)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemLink>
        ))}
      </List>
      <Divider />
      <List>
        {managementList.map((item, index) => (
          <ListItemLink key={item.label} onClick={() => handleSelect(index, item.route)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemLink>
        ))}
      </List>
        <Divider />
        <List>
            {etcList.map((item, index) => (
                <ListItemLink key={item.label} onClick={() => handleSelect(index, item.route)}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                </ListItemLink>
            ))}
        </List>
    </Drawer>
  );
};
