import { useAuth0 } from '@auth0/auth0-react';
import { Animation, EventTracker } from '@devexpress/dx-react-chart';
// import { ArgumentAxis, BarSeries, Chart, ValueAxis, Tooltip } from '@devexpress/dx-react-chart-material-ui';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import "chartjs-plugin-datalabels";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getUserStats } from '../utils/api';
import { setAccessToken } from '../utils/auth';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import RefreshIcon from '@mui/icons-material/Refresh';

export const UserStatistics = () => {
  const [daily, setDaily] = useState<any>([]);
  const [weekly, setWeekly] = useState<any>([]);
  const [monthly, setMonthly] = useState<any>([]);
  const { getAccessTokenSilently } = useAuth0();

    Chart.register(ChartDataLabels);

    const convertChartData = (dataOjb: any, rgba: string, rgba2: string) => {

        const getConvertedLabel = (label: string) => {
            const regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
            if(!regex.test(label)) return label;

            const day = new Date(label);
            const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토'];
            return `${label}(${WEEKDAY[day.getDay()]})`;
        }

        const chartLabels = dataOjb.map((item : any)=>getConvertedLabel(item._id.date));
        const chartData = dataOjb.map((item : any)=>item.count);

        const orgLabels = dataOjb.map((item : any)=>item._id.date);
        const orgUniqueLabels = orgLabels.filter((element: any, index: number) => {
            return orgLabels.indexOf(element) === index;
        });

        const orgNameLabels = orgUniqueLabels.map((item : any)=>getConvertedLabel(item));

        const chartData1 : any = [];
        const chartData2 : any = [];

        orgUniqueLabels.forEach((element: any, index: number)=>{
            const filterDataObj = dataOjb.filter((item : any) => item._id.date === element);
            chartData1.push(filterDataObj.filter((item : any) => item._id.tncAgreement === true).map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData2.push(filterDataObj.filter((item : any) => item._id.tncAgreement === false).map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
        });

        const accumulatorData: any = [];

        for (const [index, value] of orgNameLabels.entries()) {
            accumulatorData.push(chartData1[index] + chartData2[index]);
        }

        let chartAccumulator : any = [];

        accumulatorData.reduce((accumulator: number, currentValue: number, currentIndex: number, array: any)=>{
            chartAccumulator.push(accumulator + currentValue);
            return accumulator + currentValue;
        }, 0);

        const data = {
            labels: orgNameLabels,
            datasets: [
                // {
                //     type: 'bar',
                //     label: '순증',
                //     backgroundColor: rgba || 'rgba(143, 120, 75, 0.5)',
                //     // backgroundColor: '#a',
                //     // borderColor: 'darkgray',
                //     // borderWidth: 1,
                //     data: chartData,
                //     yAxisID: 'y-axis-1',
                //     datalabels: {
                //         display: true,
                //         // color: 'yellow'
                //     }
                // },

                {
                    type: 'bar',
                    label: '가입',
                    backgroundColor: rgba || 'rgba(143, 120, 75, 0.5)',
                    data: chartData1,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 1',
                    order: 1,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: '가입(임시)',
                    backgroundColor: rgba2,
                    data: chartData2,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 1',
                    order: 1,
                    maxBarThickness: 150,
                },
                {
                    type: 'line',
                    label: '누적',
                    borderColor: 'rgb(54, 162, 235)',
                    borderWidth: 2,
                    fill: false,
                    data: chartAccumulator,
                    yAxisID: 'y-axis-2',
                    datalabels: {
                        display: false,
                        // color: 'yellow'
                    }
                    ,
                    order: 2,
                },
            ],
        };
        return data;

    }

  const constructUserStat = async () => {
    setAccessToken(await getAccessTokenSilently());
    const userStats = await getUserStats();

    const dailyChart = convertChartData(userStats.daily, 'rgba(143, 120, 75, 0.5)', 'rgba(143, 120, 75, 0.3)');
    const weeklyChart = convertChartData(userStats.weekly, 'rgba(0, 52, 88, 0.5)', 'rgba(0, 52, 88, 0.3)');
    const monthlyChart = convertChartData(userStats.monthly, 'rgba(75, 0, 130, 0.5)', 'rgba(75, 0, 130, 0.3)');

    console.log(dailyChart);

    setDaily(dailyChart);
    setWeekly(weeklyChart);
    setMonthly(monthlyChart);
  };

    const options = {
        plugins: {
            datalabels: {
                display: true,
                // color: "white"
                color: "black",
            }
        },
        scales: {
            // 'y-axis-1': {
            //     maxBarThickness: 100,
            //     ticks: {
            //         fontColor: '#fff',
            //         fontStyle: 'normal',
            //         fontSize: 13,
            //         padding: -170,
            //     },
            // },
            'y-axis-2': {
                position: 'right',
                title: {
                    display: true,
                    align: 'end',
                    color: '#808080',
                    font: {
                        size: 12,
                        family: "'Noto Sans KR', sans-serif",
                        weight: 300,
                    },
                    text: '누적 사용자수'
                },
                // afterDataLimits: (scale) => {
                //     scale.max = scale.max * 1.2;
                // },
            },

            // yAxes: [
            //     {
            //         type: 'linear',
            //         display: true,
            //         position: 'left',
            //         id: 'y-axis-1',
            //     },
            //     {
            //         type: 'linear',
            //         display: true,
            //         position: 'right',
            //         id: 'y-axis-2',
            //         gridLines: {
            //             drawOnArea: false,
            //         },
            //     },
            // ],
        },
    };



  // const daily_labels = daily.map((item : any)=>item._id);
  // const daily_data = daily.map((item : any)=>item.count);
  //
  //   let daily_accumulator : any = [];
  //
  //   daily_data.reduce((accumulator: number, currentValue: number, currentIndex: number, array: any)=>{
  //       daily_accumulator.push(accumulator + currentValue);
  //       return accumulator + currentValue;
  //   }, 0);

    // const data = {
    //     labels: daily_labels,
    //     datasets: [
    //         {
    //             type: 'bar',
    //             label: '순증',
    //             data: daily_data,
    //             yAxisID: 'y-axis-1',
    //         },
    //         {
    //             type: 'line',
    //             label: '누적',
    //             borderColor: 'rgb(54, 162, 235)',
    //             borderWidth: 2,
    //             fill: false,
    //             data: daily_accumulator,
    //             yAxisID: 'y-axis-2',
    //         },
    //     ],
    // };
    //
    // const options = {
    //     scales: {
    //         yAxes: [
    //             {
    //                 type: 'linear',
    //                 display: true,
    //                 position: 'left',
    //                 id: 'y-axis-1',
    //             },
    //             {
    //                 type: 'linear',
    //                 display: true,
    //                 position: 'right',
    //                 id: 'y-axis-2',
    //                 gridLines: {
    //                     drawOnArea: false,
    //                 },
    //             },
    //         ],
    //     },
    // };


    useEffect(() => {
    constructUserStat();
  }, []);
  if (daily === [] || weekly === [] || monthly === []) {
    return <h1>Loading...</h1>;
  }
  return (
    <div>
      <h1>Daily 회원가입 현황 (최근 30일)</h1>
      {/*<Chart data={daily} width={1160}>*/}
      {/*  <ArgumentAxis />*/}
      {/*  <ValueAxis />*/}
      {/*  <BarSeries barWidth={0.4} color="#7d3131bf" valueField="count" argumentField="_id" />*/}
      {/*  <EventTracker />*/}
      {/*  <Tooltip />*/}
      {/*  <Animation />*/}
      {/*</Chart>*/}
      <div style={{width: '1200px'}}>
        <Bar data={daily} options={options} />
      </div>
      <hr />
      <h1>Weekly 회원가입 현황</h1>
      {/*<Chart data={weekly} width={1160}>*/}
      {/*  <ArgumentAxis />*/}
      {/*  <ValueAxis />*/}
      {/*  <BarSeries barWidth={0.4} color="#c6c43f99" valueField="count" argumentField="_id" />*/}
      {/*  <EventTracker />*/}
      {/*  <Tooltip />*/}
      {/*  <Animation />*/}
      {/*</Chart>*/}
        <div style={{width: '1200px'}}>
            <Bar data={weekly} options={options} />
        </div>
      <hr />
      <h1>Monthly 회원가입 현황</h1>
      {/*<Chart data={monthly} width={1160}>*/}
      {/*  <ArgumentAxis />*/}
      {/*  <ValueAxis />*/}
      {/*  <BarSeries barWidth={0.4} color="#7f8a8d77" valueField="count" argumentField="_id" />*/}

      {/*  <EventTracker />*/}
      {/*  <Tooltip />*/}
      {/*  <Animation />*/}
      {/*</Chart>*/}
        <div style={{width: '1200px'}}>
            <Bar data={monthly} options={options} />
        </div>
      <hr />

        <Box sx={{ '& > :not(style)': { m: 1 } }} style={{position: 'fixed', top: '70px', left: '950px'}}>
            <Fab color="primary" aria-label="refresh">
                <RefreshIcon onClick={constructUserStat} />
            </Fab>
            {/*<Fab color="secondary" aria-label="edit">*/}
            {/*    <EditIcon />*/}
            {/*</Fab>*/}
            {/*<Fab variant="extended">*/}
            {/*    <NavigationIcon sx={{ mr: 1 }} />*/}
            {/*    Navigate*/}
            {/*</Fab>*/}
            {/*<Fab disabled aria-label="like">*/}
            {/*    <FavoriteIcon />*/}
            {/*</Fab>*/}
        </Box>

    </div>
  );
};
