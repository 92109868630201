import { useAuth0 } from '@auth0/auth0-react';
import { Animation, EventTracker } from '@devexpress/dx-react-chart';
// import { ArgumentAxis, BarSeries, Chart, ValueAxis, Tooltip } from '@devexpress/dx-react-chart-material-ui';
import React, { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import "chartjs-plugin-datalabels";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getViewStats } from '../utils/api';
import { setAccessToken } from '../utils/auth';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import RefreshIcon from '@mui/icons-material/Refresh';

export const ViewStatistics = () => {
    const [daily, setDaily] = useState<any>([]);
    const [weekly, setWeekly] = useState<any>([]);
    const [monthly, setMonthly] = useState<any>([]);
    const [hourly, setHourly] = useState<any>([]);
    const [userAgent, setUserAgent] = useState<any>([]);

    const [dailyUser, setDailyUser] = useState<any>([]);
    const { getAccessTokenSilently } = useAuth0();

    Chart.register(ChartDataLabels);

    const convertChartData = (dataOjb: any, rgba: string) => {

        const getConvertedLabel = (label: string) => {
            const regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
            if(!regex.test(label)) return label;

            const day = new Date(label);
            const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토'];
            return `${label}(${WEEKDAY[day.getDay()]})`;
        }

        const orgLabels = dataOjb.map((item : any)=>item._id.date);
        const orgUniqueLabels = orgLabels.filter((element: any, index: number) => {
            return orgLabels.indexOf(element) === index;
        });

        const tempLabels = dataOjb.map((item : any)=>getConvertedLabel(item._id.date));
        const chartLabels = tempLabels.filter((element: any, index: number) => {
            return tempLabels.indexOf(element) === index;
        });

        // const chartData1 = dataOjb.filter((item : any) => item._id.userAgent === 'ios').map((item : any)=>item.count);
        // const chartData2 = dataOjb.filter((item : any) => item._id.userAgent === 'android').map((item : any)=>item.count);
        // const chartData3 = dataOjb.filter((item : any) => item._id.userAgent === 'web').map((item : any)=>item.count);

        const chartData1 : any = [];
        const chartData2 : any = [];
        const chartData3 : any = [];

        orgUniqueLabels.forEach((element: any, index: number)=>{
            const filterDataObj = dataOjb.filter((item : any) => item._id.date === element);
            chartData1.push(filterDataObj.filter((item : any) => item._id.userAgent === 'ios').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData2.push(filterDataObj.filter((item : any) => item._id.userAgent === 'android').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData3.push(filterDataObj.filter((item : any) => item._id.userAgent === 'web').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
        });

        const chartSum : any = [];
        orgUniqueLabels.forEach((element: any, index: number)=>{
            chartSum.push(dataOjb.filter((item : any) => item._id.date === element).map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
        });
        console.log('chartSum', chartSum);

        let chartAccumulator : any = [];
        chartSum.reduce((accumulator: number, currentValue: number, currentIndex: number, array: any)=>{
            chartAccumulator.push(accumulator + currentValue);
            return accumulator + currentValue;
        }, 0);

        const CHART_COLORS = {
            red: 'rgb(255, 99, 132)',
            orange: 'rgb(255, 159, 64)',
            yellow: 'rgb(255, 205, 86)',
            green: 'rgb(75, 192, 192)',
            blue: 'rgb(54, 162, 235)',
            purple: 'rgb(153, 102, 255)',
            grey: 'rgb(201, 203, 207)'
        };

        const data = {
            labels: chartLabels,
            datasets: [
                {
                    type: 'bar',
                    label: 'iOS',
                    backgroundColor: CHART_COLORS.red,
                    data: chartData1,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 0',
                    order: 3,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'Android',
                    backgroundColor: CHART_COLORS.blue,
                    data: chartData2,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 0',
                    order: 4,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'Web',
                    backgroundColor: CHART_COLORS.green,
                    data: chartData3,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 0',
                    order: 5,
                    maxBarThickness: 150,
                },
                {
                    type: 'line',
                    label: 'total',
                    backgroundColor: CHART_COLORS.orange,
                    data: chartSum,
                    yAxisID: 'y-axis-1',
                    // stack: 'Stack 0',
                    order: 2,
                    datalabels: {
                        display: true,
                        // color: 'white',
                        font: {
                            weight: 'bold'
                        },
                        align: 'end',
                        // anchor: 'end',
                        padding: {
                            bottom: 10
                        },
                        margin: {
                            bottom: 100
                        }
                    }
                },
                {
                    type: 'line',
                    label: '누적',
                    backgroundColor: CHART_COLORS.purple,
                    // backgroundColor: '#a',
                    // borderColor: 'darkgray',
                    // borderWidth: 1,
                    data: chartAccumulator,
                    yAxisID: 'y-axis-2',
                    // stack: 'Stack 0',
                    order: 1,
                    datalabels: {
                        display: false,
                    }
                },
            ],
        };
        return data;

    }

    const convertPieChartData = (dataObj: any, rgba: string) => {

        const getConvertedLabel = (userAgent: string, mobile: any) => {
            if(userAgent === 'android' || userAgent === 'ios' ) return userAgent;

            console.log('mobile', mobile);

            if(mobile === undefined) return 'web(unknown)';
            if(mobile) {
                return 'web(mobile)';
            }else{
                return 'web(desktop)';
            }
        }

        const chartLabels: any = ['ios','android', 'web(mobile)', 'web(desktop)',  'web(unknown)'];
        const chartData : any = [];

        chartData.push(dataObj.filter((item : any) => item._id.userAgent === 'ios').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
        chartData.push(dataObj.filter((item : any) => item._id.userAgent === 'android').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
        chartData.push(dataObj.filter((item : any) => item._id.userAgent === 'web' && getConvertedLabel(item._id.userAgent, item._id.mobile) === 'web(mobile)').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
        chartData.push(dataObj.filter((item : any) => item._id.userAgent === 'web' && getConvertedLabel(item._id.userAgent, item._id.mobile) === 'web(desktop)').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
        chartData.push(dataObj.filter((item : any) => item._id.userAgent === 'web' && getConvertedLabel(item._id.userAgent, item._id.mobile) === 'web(unknown)').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));

        console.log('chartData===>', chartData);

        const CHART_COLORS = {
            red: 'rgb(255, 99, 132)',
            blue: 'rgb(54, 162, 235)',
            green: 'rgb(75, 192, 192)',
            orange: 'rgb(255, 159, 64)',
            yellow: 'rgb(255, 205, 86)',
        };

        const data = {
            labels: chartLabels,
            datasets: [
                {
                    type: 'pie',
                    // label: 'iOS',
                    backgroundColor: [CHART_COLORS.red, CHART_COLORS.blue, CHART_COLORS.green, CHART_COLORS.orange, CHART_COLORS.yellow],
                    data: chartData,
                    // yAxisID: 'y-axis-1',
                    // stack: 'Stack 0',
                    // order: 3
                }
            ],
        };

        console.log('data===>', data);

        return data;

    }

    const constructViewStat = async () => {
        setAccessToken(await getAccessTokenSilently());
        const userStats = await getViewStats();

        const dailyChart = convertChartData(userStats.daily, '');
        const weeklyChart = convertChartData(userStats.weekly, 'rgba(0, 52, 88, 0.5)');
        const monthlyChart = convertChartData(userStats.monthly, 'rgba(75, 0, 130, 0.5)');
        const hourlyChart = convertChartData(userStats.hourly, 'rgba(75, 0, 130, 0.5)');

        const userAgentChart = convertPieChartData(userStats.userAgent, 'rgba(75, 0, 130, 0.5)');

        const dailyUserChart = convertChartData(userStats.dailyUser, '');

        console.log(dailyChart);

        setDaily(dailyChart);
        setWeekly(weeklyChart);
        setMonthly(monthlyChart);
        setHourly(hourlyChart);
        setUserAgent(userAgentChart);
        setDailyUser(dailyUserChart);
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            // title: {
            //     display: true,
            //     text: 'Chart.js Combined Line/Bar Chart'
            // }
        },
        radius: 4,
        scales: {
            // xAxes: [{
            //     maxBarThickness: 100,
            // }],
            'y-axis-2': {
                position: 'right',
                title: {
                    display: true,
                    // align: 'end',
                    color: '#808080',
                    font: {
                        size: 12,
                        family: "'Noto Sans KR', sans-serif",
                        weight: 300,
                    },
                    text: '누적 건수'
                },
            },
        }
    };


    const pieOptions =  {
        plugins: {
            datalabels: {
                backgroundColor: 'gray',
                borderRadius: 4,
                color: 'white',
                font: {
                    weight: 'bold'
                },
                // font: {
                //     color:'black',
                //     size: 15,
                //     family: "'Noto Sans KR', sans-serif",
                //     weight: 500,
                // },
            //     formatter: (value, ctx) => {
            //         let datasets = ctx.chart.data.datasets;
            //
            //         if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
            //             let sum = datasets[0].data.reduce((a, b) => a + b, 0);
            //             let percentage = Math.round((value / sum) * 100) + "%";
            //             return percentage;
            //         } else {
            //             return percentage;
            //         }
            //     },
            //     color: "red"
            }
        }
    };

    useEffect(() => {
        constructViewStat();
    }, []);
    if (daily === [] || weekly === [] || monthly === [] || userAgent === []) {
        return <h1>Loading...</h1>;
    }
    return (
        <div>
            <h1>Daily 아티클 페이지뷰 현황 (최근 30일)</h1>
            <div style={{width: '1200px'}}>
                <Bar data={daily} options={options} />
            </div>
            <hr />
            <h1>Weekly 아티클 페이지뷰 현황</h1>
            <div style={{width: '1200px'}}>
                <Bar data={weekly} options={options} />
            </div>
            <hr />
            <h1>Monthly 아티클 페이지뷰 현황</h1>
            <div style={{width: '1200px'}}>
                <Bar data={monthly} options={options} />
            </div>
            <hr />

            <h1>Hourly 아티클 페이지뷰 현황</h1>
            <div style={{width: '1200px'}}>
                <Bar data={hourly} options={options} />
            </div>
            <hr />

            <h1>Daily 아티클 페이지뷰 사용자 현황 (최근 30일)</h1>
            <div style={{width: '1200px'}}>
                <Bar data={dailyUser} options={options} />
            </div>
            <hr />

            <h1>아티클 페이지뷰 디바이스 비율 (userAgent)</h1>
            <div style={{width: '600px'}}>
                {/*<Pie data={userAgent} options={options} />*/}
                <Pie data={userAgent} width={500} height={500} options={pieOptions} />
            </div>
            <hr />

            <Box sx={{ '& > :not(style)': { m: 1 } }} style={{position: 'fixed', top: '70px', left: '950px'}}>
                <Fab color="primary" aria-label="refresh">
                    <RefreshIcon onClick={constructViewStat} />
                </Fab>
            </Box>

        </div>
    );
};
