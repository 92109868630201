import { useAuth0 } from '@auth0/auth0-react';
// import { Animation, EventTracker } from '@devexpress/dx-react-chart';
// import { ArgumentAxis, BarSeries, Chart, ValueAxis, Tooltip } from '@devexpress/dx-react-chart-material-ui';
import React, { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';

// import { Line } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { getUserTypeStats } from '../utils/api';
import { setAccessToken } from '../utils/auth';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import RefreshIcon from '@mui/icons-material/Refresh';

export const UserTypeStatistics = () => {
    const [daily, setDaily] = useState<any>([]);
    const [weekly, setWeekly] = useState<any>([]);
    const [weeklyDetail, setWeeklyDetail] = useState<any>([]);
    const [weeklyPercentage, setWeeklyPercentage] = useState<any>([]);
    const [hourly, setHourly] = useState<any>([]);
    const [userAgent, setUserAgent] = useState<any>([]);

    const [dailyUser, setDailyUser] = useState<any>([]);
    const { getAccessTokenSilently } = useAuth0();

    Chart.register(ChartDataLabels);

    const convertChartData = (dataOjb: any, rgba: string) => {

        const getConvertedLabel = (label: string) => {
            const regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
            if(!regex.test(label)) return label;

            const day = new Date(label);
            const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토'];
            return `${label}(${WEEKDAY[day.getDay()]})`;
        }

        const orgLabels = dataOjb.map((item : any)=>item._id.yearWeek);
        const orgUniqueLabels = orgLabels.filter((element: any, index: number) => {
            return orgLabels.indexOf(element) === index;
        });

        // const orgUniqueLabels = ['W', 'D', 'E1', 'E2', 'E3', 'E4', 'E5', 'B', 'R', 'C'];

        const tempLabels = dataOjb.map((item : any)=>getConvertedLabel(item._id.yearWeek));
        const chartLabels = tempLabels.filter((element: any, index: number) => {
            return tempLabels.indexOf(element) === index;
        });

        const chartData1 : any = [];
        const chartData2 : any = [];
        // const chartData3 : any = [];

        const chartData3_1 : any = [];
        const chartData3_2 : any = [];
        const chartData3_3 : any = [];
        const chartData3_4 : any = [];
        const chartData3_5 : any = [];

        const chartData4 : any = [];
        const chartData5 : any = [];
        const chartData6 : any = [];

        orgUniqueLabels.forEach((element: any, index: number)=>{
            const filterDataObj = dataOjb.filter((item : any) => item._id.yearWeek === element);
            chartData1.push(filterDataObj.filter((item : any) => item._id.type.charAt(0) === 'W').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData2.push(filterDataObj.filter((item : any) => item._id.type.charAt(0) === 'D').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            // chartData3.push(filterDataObj.filter((item : any) => item._id.type.charAt(0) === 'E').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));

            chartData3_1.push(filterDataObj.filter((item : any) => item._id.type.substr(0, 2) === 'E1').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData3_2.push(filterDataObj.filter((item : any) => item._id.type.substr(0, 2) === 'E2').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData3_3.push(filterDataObj.filter((item : any) => item._id.type.substr(0, 2) === 'E3').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData3_4.push(filterDataObj.filter((item : any) => item._id.type.substr(0, 2) === 'E4').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData3_5.push(filterDataObj.filter((item : any) => item._id.type.substr(0, 2) === 'E5').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));

            chartData4.push(filterDataObj.filter((item : any) => item._id.type.charAt(0) === 'B').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData5.push(filterDataObj.filter((item : any) => item._id.type.charAt(0) === 'R').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData6.push(filterDataObj.filter((item : any) => item._id.type.charAt(0) === 'C').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
        });

        const chartSum : any = [];
        orgUniqueLabels.forEach((element: any, index: number)=>{
            chartSum.push(dataOjb.filter((item : any) => item._id.yearWeek === element).map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
        });
        console.log('chartSum', chartSum);

        let chartAccumulator : any = [];
        chartSum.reduce((accumulator: number, currentValue: number, currentIndex: number, array: any)=>{
            chartAccumulator.push(accumulator + currentValue);
            return accumulator + currentValue;
        }, 0);

        // const CHART_COLORS = {
        //     red: 'rgb(255, 99, 132)',
        //     orange: 'rgb(255, 159, 64)',
        //     yellow: 'rgb(255, 205, 86)',
        //     green: 'rgb(75, 192, 192)',
        //     blue: 'rgb(54, 162, 235)',
        //     purple: 'rgb(153, 102, 255)',
        //     grey: 'rgb(201, 203, 207)'
        // };

        // const CHART_COLORS = ['#1f77b4',
        //     '#aec7e8',
        //     '#ff7f0e',
        //     '#ffbb78',
        //     '#2ca02c',
        //     '#98df8a',
        //     '#d62728',
        //     '#ff9896',
        //     '#9467bd',
        //     '#c5b0d5',
        //     '#8c564b',
        //     '#c49c94',
        //     '#e377c2',
        //     '#f7b6d2',
        //     '#7f7f7f',
        //     '#c7c7c7',
        //     '#bcbd22',
        //     '#dbdb8d',
        //     '#17becf',
        //     '#9edae5'];

        // <td>
        //     '#ffffe5',
        //     '#f7fcb9',
        //     '#d9f0a3',
        //     '#addd8e',
        //     '#78c679',
        //     '#41ab5d',
        //     '#238443',
        //     '#006837',
        //     '#004529',
        // </td>

        const CHART_COLORS = [
            '#a6cee3',
            '#1f78b4',
            // '#b2df8a',

            '#ffffe5',
            '#f7fcb9',
            '#d9f0a3',
            '#addd8e',
            '#78c679',



            '#33a02c',
            '#fb9a99',
            '#e31a1c',
            '#fdbf6f',
            '#ff7f00',
            '#cab2d6',
            '#6a3d9a'];



        let colorIndex = 0;

        const data = {
            // type: "outlabeledPie",
            labels: chartLabels,
            datasets: [
                {
                    type: 'bar',
                    label: 'W(해지)',
                    // backgroundColor: CHART_COLORS.red,
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData1,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 0',
                    order: 1,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'D(휴면)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData2,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 1',
                    order: 2,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'E1(평가자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData3_1,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 2',
                    order: 3,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'E2(평가자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData3_2,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 2',
                    order: 3,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'E3(평가자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData3_3,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 2',
                    order: 3,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'E4(평가자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData3_4,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 2',
                    order: 3,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'E5(평가자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData3_5,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 2',
                    order: 3,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'B(초보자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData4,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 3',
                    order: 4,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'R(일반사용자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData5,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 4',
                    order: 5,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'C(옹호자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData6,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 5',
                    order: 6,
                    maxBarThickness: 150,
                },
                {
                    type: 'line',
                    label: '전체사용자',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartSum,
                    yAxisID: 'y-axis-2',
                    // stack: 'Stack 0',
                    order: 7
                },
                // {
                //     type: 'line',
                //     label: '누적',
                //     backgroundColor: CHART_COLORS[colorIndex++],
                //     // backgroundColor: '#a',
                //     // borderColor: 'darkgray',
                //     // borderWidth: 1,
                //     data: chartAccumulator,
                //     yAxisID: 'y-axis-2',
                //     // stack: 'Stack 0',
                //     order: 8
                // },
            ],
        };
        return data;

    }


    const convertChartDataForDetail = (dataOjb: any, rgba: string) => {

        const getConvertedLabel = (label: string) => {
            const regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
            if(!regex.test(label)) return label;

            const day = new Date(label);
            const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토'];
            return `${label}(${WEEKDAY[day.getDay()]})`;
        }

        const orgLabels = dataOjb.map((item : any)=>item._id.yearWeek);
        const orgUniqueLabels = orgLabels.filter((element: any, index: number) => {
            return orgLabels.indexOf(element) === index;
        });

        const tempLabels = dataOjb.map((item : any)=>getConvertedLabel(item._id.yearWeek));
        const chartLabels = tempLabels.filter((element: any, index: number) => {
            return tempLabels.indexOf(element) === index;
        });

        const chartData1 : any = [];
        const chartData2 : any = [];
        // const chartData3 : any = [];

        const chartData3_1 : any = [];
        const chartData3_2 : any = [];
        const chartData3_3 : any = [];
        const chartData3_4 : any = [];

        const chartData3_5_l : any = [];
        const chartData3_5_h : any = [];

        const chartData4_l : any = [];
        const chartData4_h : any = [];

        const chartData5_l : any = [];
        const chartData5_f : any = [];
        const chartData5_h : any = [];

        const chartData6_l : any = [];
        const chartData6_f : any = [];
        const chartData6_h : any = [];

        orgUniqueLabels.forEach((element: any, index: number)=>{
            const filterDataObj = dataOjb.filter((item : any) => item._id.yearWeek === element);
            chartData1.push(filterDataObj.filter((item : any) => item._id.type.charAt(0) === 'W').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData2.push(filterDataObj.filter((item : any) => item._id.type.charAt(0) === 'D').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            // chartData3.push(filterDataObj.filter((item : any) => item._id.type.charAt(0) === 'E').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));

            chartData3_1.push(filterDataObj.filter((item : any) => item._id.type === 'E1').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData3_2.push(filterDataObj.filter((item : any) => item._id.type === 'E2').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData3_3.push(filterDataObj.filter((item : any) => item._id.type === 'E3').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData3_4.push(filterDataObj.filter((item : any) => item._id.type === 'E4').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData3_5_l.push(filterDataObj.filter((item : any) => item._id.type === 'E5-L').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData3_5_h.push(filterDataObj.filter((item : any) => item._id.type === 'E5-H').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));

            chartData4_l.push(filterDataObj.filter((item : any) => item._id.type === 'B-L').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData4_h.push(filterDataObj.filter((item : any) => item._id.type === 'B-H').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData5_l.push(filterDataObj.filter((item : any) => item._id.type === 'R-L').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData5_f.push(filterDataObj.filter((item : any) => item._id.type === 'R-F').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData5_h.push(filterDataObj.filter((item : any) => item._id.type === 'R-H').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData6_l.push(filterDataObj.filter((item : any) => item._id.type === 'C-L').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData6_f.push(filterDataObj.filter((item : any) => item._id.type === 'C-F').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
            chartData6_h.push(filterDataObj.filter((item : any) => item._id.type === 'C-H').map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
        });

        // const chartSum : any = [];
        // orgUniqueLabels.forEach((element: any, index: number)=>{
        //     chartSum.push(dataOjb.filter((item : any) => item._id.yearWeek === element).map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0));
        // });
        // console.log('chartSum', chartSum);

        // let chartAccumulator : any = [];
        // chartSum.reduce((accumulator: number, currentValue: number, currentIndex: number, array: any)=>{
        //     chartAccumulator.push(accumulator + currentValue);
        //     return accumulator + currentValue;
        // }, 0);

        // const CHART_COLORS = {
        //     red: 'rgb(255, 99, 132)',
        //     orange: 'rgb(255, 159, 64)',
        //     yellow: 'rgb(255, 205, 86)',
        //     green: 'rgb(75, 192, 192)',
        //     blue: 'rgb(54, 162, 235)',
        //     purple: 'rgb(153, 102, 255)',
        //     grey: 'rgb(201, 203, 207)'
        // };

        // const CHART_COLORS = ['#1f77b4',
        //     '#aec7e8',
        //     '#ff7f0e',
        //     '#ffbb78',
        //     '#2ca02c',
        //     '#98df8a',
        //     '#d62728',
        //     '#ff9896',
        //     '#9467bd',
        //     '#c5b0d5',
        //     '#8c564b',
        //     '#c49c94',
        //     '#e377c2',
        //     '#f7b6d2',
        //     '#7f7f7f',
        //     '#c7c7c7',
        //     '#bcbd22',
        //     '#dbdb8d',
        //     '#17becf',
        //     '#9edae5'];

        // <td>
        //     '#ffffe5',
        //     '#f7fcb9',
        //     '#d9f0a3',
        //     '#addd8e',
        //     '#78c679',
        //     '#41ab5d',
        //     '#238443',
        //     '#006837',
        //     '#004529',
        // </td>

        const CHART_COLORS = [
            '#a6cee3',
            '#1f78b4',

            '#ffffe5',
            '#f7fcb9',
            '#d9f0a3',
            '#addd8e',

            '#7fcdbb',
            '#2c7fb8',

            '#ccece6',
            '#66c2a4',

            '#e0ecf4',
            '#9ebcda',
            '#8c6bb1',

            '#fdd49e',
            '#fc8d59',
            '#d7301f',
        ];

        let colorIndex = 0;

        const data = {
            // type: "outlabeledPie",
            labels: chartLabels,
            datasets: [
                {
                    type: 'bar',
                    label: 'W(해지)',
                    // backgroundColor: CHART_COLORS.red,
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData1,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 0',
                    order: 1,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'D(휴면)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData2,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 1',
                    order: 2,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'E1(평가자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData3_1,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 2',
                    order: 3,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'E2(평가자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData3_2,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 3',
                    order: 3,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'E3(평가자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData3_3,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 4',
                    order: 3,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'E4(평가자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData3_4,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 5',
                    order: 3,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'E5-L(평가자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData3_5_l,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 6',
                    order: 3,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'E5-H(평가자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData3_5_h,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 6',
                    order: 3,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'B-L(초보자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData4_l,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack b',
                    order: 4,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'B-H(초보자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData4_h,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack b',
                    order: 4,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'R-L(일반사용자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData5_l,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack r',
                    order: 5,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'R-F(일반사용자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData5_f,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack r',
                    order: 5,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'R-H(일반사용자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData5_h,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack r',
                    order: 5,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'C-L(옹호자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData6_l,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack c',
                    order: 6,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'C-F(옹호자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData6_f,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack c',
                    order: 6,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: 'C-H(옹호자)',
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData6_h,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack c',
                    order: 6,
                    maxBarThickness: 150,
                },
                // {
                //     type: 'line',
                //     label: '전체사용자',
                //     backgroundColor: CHART_COLORS[colorIndex++],
                //     data: chartSum,
                //     yAxisID: 'y-axis-2',
                //     // stack: 'Stack 0',
                //     order: 7
                // },
                // {
                //     type: 'line',
                //     label: '누적',
                //     backgroundColor: CHART_COLORS[colorIndex++],
                //     // backgroundColor: '#a',
                //     // borderColor: 'darkgray',
                //     // borderWidth: 1,
                //     data: chartAccumulator,
                //     yAxisID: 'y-axis-2',
                //     // stack: 'Stack 0',
                //     order: 8
                // },
            ],
        };
        return data;

    }



    const convertChartDataForPercentage = (dataOjb: any, rgba: string) => {

        const getConvertedLabel = (label: string) => {
            const regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
            if(!regex.test(label)) return label;

            const day = new Date(label);
            const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토'];
            return `${label}(${WEEKDAY[day.getDay()]})`;
        }

        const yearWeeks = dataOjb.map((item : any)=>item._id.yearWeek);
        const uniqueYearWeeks = Array.from(new Set(yearWeeks));
        const targetYearWeek = uniqueYearWeeks.reduce((a: any, b: any) => a > b ? a : b);
        const prevYearWeek = uniqueYearWeeks.filter((element: any)=> element !== targetYearWeek).reduce((a: any, b: any) => a > b ? a : b);

        const array = ['0', 1, 2, '0', '0', 3]
        Array.from(new Set(array));


        // const orgLabels = dataOjb.map((item : any)=>item._id.type);
        // const orgUniqueLabels = orgLabels.filter((element: any, index: number) => {
        //     return orgLabels.indexOf(element) === index;
        // });
        const orgUniqueLabels = ['W', 'D', 'E1', 'E2', 'E3', 'E4', 'E5-L', 'E5-H', 'B-L', 'B-H', 'R-L', 'R-F', 'R-H', 'C-L', 'C-F', 'C-H'];


        // const convertedLabels = dataOjb.map((item : any)=>getConvertedLabel(item._id.type));
        // const chartLabels = convertedLabels.filter((element: any, index: number) => {
        //     return convertedLabels.indexOf(element) === index;
        // });

        const chartLabels = ['W', 'D', 'E1', 'E2', 'E3', 'E4', 'E5-L', 'E5-H', 'B-L', 'B-H', 'R-L', 'R-F', 'R-H', 'C-L', 'C-F', 'C-H'];

        // const chartData1 : any = dataOjb.filter((item : any) => item._id.yearWeek === targetYearWeek).map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0);
        // const chartData2 : any = dataOjb.filter((item : any) => item._id.yearWeek === prevYearWeek).map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0);
        // // const chartData3 : any = [];
        //
        const chartData1 : any = [];
        const chartData2 : any = [];
        // const chartData3_3 : any = [];
        // const chartData3_4 : any = [];
        //
        // const chartData3_5_l : any = [];
        // const chartData3_5_h : any = [];
        //
        // const chartData4_l : any = [];
        // const chartData4_h : any = [];
        //
        // const chartData5_l : any = [];
        // const chartData5_f : any = [];
        // const chartData5_h : any = [];
        //
        // const chartData6_l : any = [];
        // const chartData6_f : any = [];
        // const chartData6_h : any = [];

        console.log('targetYearWeek', targetYearWeek);



        orgUniqueLabels.forEach((element: any, index: number)=>{
            // const filterDataObj = dataOjb.filter((item : any) => item._id.yearWeek === element);
            chartData1.push(dataOjb.find((item : any) => (item._id.yearWeek === prevYearWeek && item._id.type === element))?.count || 0);
            chartData2.push(dataOjb.find((item : any) => item._id.yearWeek === targetYearWeek && item._id.type === element)?.count || 0);
     });

        const CHART_COLORS = [
            'lightgray',
            '#1f78b4',

            '#ffffe5',
            '#f7fcb9',
            '#d9f0a3',
            '#addd8e',

            '#7fcdbb',
            '#2c7fb8',

            '#ccece6',
            '#66c2a4',

            '#e0ecf4',
            '#9ebcda',
            '#8c6bb1',

            '#fdd49e',
            '#fc8d59',
            '#d7301f',
        ];

        let colorIndex = 0;

        console.log('chartData1', chartData1);

        const data = {
            labels: chartLabels,
            datasets: [
                {
                    type: 'bar',
                    label: prevYearWeek,
                    // backgroundColor: CHART_COLORS.red,
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData1,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 0',
                    order: 1,
                    maxBarThickness: 150,
                },
                {
                    type: 'bar',
                    label: targetYearWeek,
                    backgroundColor: CHART_COLORS[colorIndex++],
                    data: chartData2,
                    yAxisID: 'y-axis-1',
                    stack: 'Stack 1',
                    order: 2,
                    maxBarThickness: 150,
                },

            ],
        };
        return data;

    }

    var weeklyDataObject : any = [];

    const constructUserTypeStat = async () => {
        setAccessToken(await getAccessTokenSilently());
        const userTypeStats = await getUserTypeStats();

        const weeklyChart = convertChartData(userTypeStats.weekly, 'rgba(0, 52, 88, 0.5)');
        setWeekly(weeklyChart);

        const weeklyDetailChart = convertChartDataForDetail(userTypeStats.weekly, 'rgba(0, 52, 88, 0.5)');
        setWeeklyDetail(weeklyDetailChart);

        console.log('weeklyDetailChart', weeklyDetailChart);

        const weeklyPercentageChart = convertChartDataForPercentage(userTypeStats.weekly, 'rgba(0, 52, 88, 0.5)');
        setWeeklyPercentage(weeklyPercentageChart);

        console.log('weeklyPercentageChart', weeklyPercentageChart);

        weeklyDataObject = userTypeStats.weekly;

        console.log('weeklyDataObject', weeklyDataObject);



    };

    const getSum = (yearWeek: string) => {
        console.log('getSum', weeklyDataObject);
        if(!weeklyDataObject) return 0;
        const sum : number = weeklyDataObject.filter((item : any) => item._id.yearWeek === yearWeek).map((item : any)=>item.count).reduce((sum: number, currentValue: number)=> sum+currentValue, 0);
        console.log(sum);
        return sum;
    }

    const footer = (tooltipItems: any) => {
        let sum = 0;
        let label: string = '';
        let total = tooltipItems[0].dataset.data.reduce((a: number, b: number) => a + b, 0);

        console.log('tooltipItems', tooltipItems);

        tooltipItems.forEach(function(tooltipItem: any) {
            sum += tooltipItem.parsed.y;
            label = tooltipItem.dataset.label;
        });
        return '비율: ' + Math.round(sum/total*100*100)/100 + '%';
    };

    const options = {
        // showTooltips: false,
        // onAnimationComplete: function () {
        //
        //     var ctx = this.chart.ctx;
        //     ctx.font = this.scale.font;
        //     ctx.fillStyle = this.scale.textColor
        //     ctx.textAlign = "center";
        //     ctx.textBaseline = "bottom";
        //
        //     this.datasets.forEach(function (dataset) {
        //         dataset.bars.forEach(function (bar) {
        //             ctx.fillText(bar.value, bar.x, bar.y - 5);
        //         });
        //     })
        // },


        responsive: true,
        plugins: {

            datalabels: {
                display: true,
                // color: "white"
                color: "black",
            }


            // datalabels: {
            //     formatter: (value, context) => {
            //         let total = context.chart.data.datasets[context.dataIndex].data.reduce((a, b) => a + b, 0);
            //         return Math.round(1000 / total * value) / 10 + '%';
            //     },
            //     color: 'white'
            // }

            // "legend": true,
            // "outlabels": {
            //     "text": "%l %p",
            //     "color": "white",
            //     "stretch": 35,
            //     "font": {
            //         "resizable": true,
            //         "minSize": 12,
            //         "maxSize": 18
            //     }
            // },

            // legend: {
            //     position: 'top',
            // },
            // datalabels: {
            //     display: true,
            //     backgroundColor: '#ccc',
            //     borderRadius: 3,
            //     font: {
            //         color: 'red',
            //         weight: 'bold',
            //     }
            // },
            // datalabels: {
            //     display: true,
            //     align: 'bottom',
            //     backgroundColor: '#ccc',
            //     borderRadius: 3
            // },
            // title: {
            //     display: true,
            //     text: 'Chart.js Combined Line/Bar Chart'
            // }
            // colorschemes: {
            //     scheme: 'brewer.Paired12'
            // }
        },
        radius: 4,
        scales: {
            'y-axis-1': [{
                maxBarThickness: 100,
                ticks: {
                    fontColor: '#fff',
                    fontStyle: 'normal',
                    fontSize: 13,
                    padding: -170,
                },
            }],
            'y-axis-2': {
                position: 'right',
                title: {
                    display: true,
                    align: 'end',
                    color: '#808080',
                    font: {
                        size: 12,
                        family: "'Noto Sans KR', sans-serif",
                        weight: 300,
                    },
                    text: '사용자: 수'
                },
                // afterDataLimits: (scale) => {
                //     scale.max = scale.max * 1.2;
                // },
            },
        }
    };

    const options2 = {
        responsive: true,
        plugins: {
            datalabels: {
                display: true,
                color: "black",
            }
        },
        radius: 4,
        scales: {
            'y-axis-1': [{
                maxBarThickness: 100,
                ticks: {
                    fontColor: '#fff',
                    fontStyle: 'normal',
                    fontSize: 13,
                    padding: -170,
                },
            }],
        }
    };

    const options3 = {
        responsive: true,
        plugins: {
            datalabels: {
                display: true,
                color: "black",
            },
            tooltip: {
                callbacks: {
                    footer: footer,
                }
            }
        },
        radius: 4,
        scales: {
            'y-axis-1': [{
                maxBarThickness: 100,
                ticks: {
                    fontColor: '#fff',
                    fontStyle: 'normal',
                    fontSize: 13,
                    padding: -170,
                },
            }],
        }
    };


    const pieOptions =  {
        plugins: {
            // datalabels: {
            //     formatter: (value, ctx) => {
            //         let datasets = ctx.chart.data.datasets;
            //
            //         if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
            //             let sum = datasets[0].data.reduce((a, b) => a + b, 0);
            //             let percentage = Math.round((value / sum) * 100) + "%";
            //             return percentage;
            //         } else {
            //             return percentage;
            //         }
            //     },
            //     color: "red"
            // }
        }
    };

    useEffect(() => {
        constructUserTypeStat();
    }, []);
    if (daily === [] || weekly === [] || weeklyDetail === [] || userAgent === []) {
        return <h1>Loading...</h1>;
    }
    return (
        <div>
            <h1>Weekly 사용자 유형별</h1>
            <div>
            <a href='https://heybunny.atlassian.net/wiki/spaces/HEYBUNNY/pages/2916353#%EC%82%AC%EC%9A%A9%EC%9E%90-%EC%9C%A0%ED%98%95-%EC%A0%95%EC%9D%98' target='_blank'>사용자 유형 정의</a>
            </div>
            <div style={{width: '1200px'}}>
                <Bar data={weekly} options={options} />
            </div>

            <h1>Weekly 사용자 세부 유형별</h1>
            <div style={{width: '1200px'}}>
                <Bar data={weeklyDetail} options={options2} />
            </div>

            <h1>최근 2주간 사용자 유형 비교</h1>
            <div style={{width: '1200px'}}>
                <Bar data={weeklyPercentage} options={options3} />
            </div>

            <Box sx={{ '& > :not(style)': { m: 1 } }} style={{position: 'fixed', top: '70px', left: '950px'}}>
                <Fab color="primary" aria-label="refresh">
                    <RefreshIcon onClick={constructUserTypeStat} />
                </Fab>
            </Box>

        </div>
    );
};
