import axios, { AxiosRequestConfig } from 'axios';
import { accessToken } from './auth';

const restApi = axios.create({
  baseURL: 'https://povau83aq6.execute-api.ap-northeast-2.amazonaws.com/dev',
});

const getHeader = () => {
  return { Authorization: `Bearer ${accessToken}` };
};

const jsonToUrlParams = (json: any) => {
  let params = '';
  for (const key in json) {
    if (json.hasOwnProperty(key)) {
      if (params.length > 0) {
        params += '&';
      }
      params += encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
    }
  }
  return params;
};

restApi.interceptors.request.use(async (config: AxiosRequestConfig) => {
  console.log('accessToken: ', accessToken);
  config.headers = getHeader();
  return config;
});

export const getNewsletterList = async (query: any) => {
  const response = await restApi.get('/newsletters', query);
  return response.data;
};

export const getCurationList = async (query: any) => {
  const response = await restApi.get('/curations', query);
  return response.data;
};

export const getBannerList = async (query: any) => {
  const response = await restApi.get('/banners', query);
  return response.data;
};

export const getCategoryList = async (query: any) => {
  const response = await restApi.get('/categories', query);
  return response.data;
};

export const getNewsletter = async (_id: string) => {
  const response = await restApi.get(`/newsletters/${_id}`);
  console.log('response: ', response);
  return response.data;
};

export const putNewsletter = async (_id: string, content: any) => {
  const response = await restApi.put(`/newsletters/${_id}`, content);
  console.log('response: ', response);
  return response.data;
};

export const putCuration = async (_id: string, content: any) => {
  const response = await restApi.put(`/curations/${_id}`, content);
  console.log('response: ', response);
  return response.data;
};

export const postCuration = async (content: any) => {
  const params = jsonToUrlParams({_method: 'postCuration'});
  const response = await restApi.post(`/crud?${params}`, content);
  console.log('response: ', response);
  return response.data;
};

export const putBanner = async (_id: string, content: any) => {
  const response = await restApi.put(`/banners/${_id}`, content);
  console.log('response: ', response);
  return response.data;
};

export const putNewsletterBanner = async (_id: string, content: any) => {
  const response = await restApi.put(`/banners/${_id}?bannerType=newsletter`, content);
  console.log('response: ', response);
  return response.data;
};

export const putCategory = async (_id: string, content: any) => {
  const response = await restApi.put(`/categories/${_id}`, content);
  console.log('response: ', response);
  return response.data;
};

export const postCategory = async (content: any) => {
  const response = await restApi.post(`/categories`, content);
  console.log('response: ', response);
  return response.data;
};

export const  getCurationCategoryList = async (query: any) => {

  query.params._method = 'getCurationCategoryList';
  const params = jsonToUrlParams(query.params);
  const response = await restApi.post(`/crud?${params}`, {});
  return response.data;
};

export const putCurationCategory = async (_id: string, content: any) => {
  const params = jsonToUrlParams({_method: 'putCurationCategory', _id: _id});
  const response = await restApi.post(`/crud?${params}`, content);
  console.log('response: ', response);
  return response.data;
};

export const postCurationCategory = async (content: any) => {
  const params = jsonToUrlParams({_method: 'postCurationCategory'});
  const response = await restApi.post(`/crud?${params}`, content);
  console.log('response: ', response);
  return response.data;
};

//sticker

export const getStickerList = async (query: any) => {
  query.params._method = 'getStickerList';
  const params = jsonToUrlParams(query.params);
  const response = await restApi.post(`/crud?${params}`, {});
  return response.data;
};

export const postSticker = async (content: any) => {
  const params = jsonToUrlParams({_method: 'postSticker'});
  const response = await restApi.post(`/crud?${params}`, content);
  console.log('response: ', response);
  return response.data;
};

export const putSticker = async (_id: string, content: any) => {
  const params = jsonToUrlParams({_method: 'putSticker', _id: _id});
  const response = await restApi.post(`/crud?${params}`, content);
  console.log('response: ', response);
  return response.data;
};

export const  getStickerCategoryList = async (query: any) => {
  query.params._method = 'getStickerCategoryList';
  const params = jsonToUrlParams(query.params);
  const response = await restApi.post(`/crud?${params}`, {});
  return response.data;
};

export const putStickerCategory = async (_id: string, content: any) => {
  const params = jsonToUrlParams({_method: 'putStickerCategory', _id: _id});
  const response = await restApi.post(`/crud?${params}`, content);
  console.log('response: ', response);
  return response.data;
};

export const postStickerCategory = async (content: any) => {
  const params = jsonToUrlParams({_method: 'postStickerCategory'});
  const response = await restApi.post(`/crud?${params}`, content);
  console.log('response: ', response);
  return response.data;
};

export const getUserList = async (query: any) => {
  const response = await restApi.get('/users', query);
  return response.data;

};

export const putUser = async (_id: string, content: any) => {
  const params = jsonToUrlParams({_method: 'putUser', _id: _id});
  const response = await restApi.post(`/crud?${params}`, content);
  console.log('response: ', response);
  return response.data;
};

export const getUserStats = async () => {
  const response = await restApi.get('/user-stats');
  return response.data;
};

export const getUserTypeStats = async () => {
  const query: any = {params: {type: 'usertype'}};
  const response = await restApi.get('/user-stats', query);
  return response.data;
};

export const getViewStats = async () => {
  const query: any = {params: {type: 'view'}};
  const response = await restApi.get('/user-stats', query);
  return response.data;
};

export const getNewsletterStats = async () => {
  const query: any = {params: {type: 'newsletter'}};
  const response = await restApi.get('/user-stats', query);
  return response.data;
};

export const getPushNotification = async (_id: string) => {
  const response = await restApi.get(`/push-notifications/${_id}`);
  console.log('response: ', response);
  return response.data;
};

export const postPushNotification = async (content: any) => {
  const response = await restApi.post(`/push-notifications`, content);
  console.log('response: ', response);
  return response.data;
};

export const putPushNotification = async (_id: string, content: any) => {
  const response = await restApi.put(`/push-notifications/${_id}`, content);
  console.log('response: ', response);
  return response.data;
};

export const getPushNotificationList = async (query: any) => {
  const response = await restApi.get('/push-notifications', query);
  return response.data;

};

export const postSlimBanner = async (content: any) => {
  const params = jsonToUrlParams({_method: 'postSlimBanner'});
  const response = await restApi.post(`/crud?${params}`, content);
  console.log('response: ', response);
  return response.data;
};

export const getAttendanceBannerList = async (query: any) => {

  query.params._method = 'getAttendanceBannerList';
  const params = jsonToUrlParams(query.params);
  const response = await restApi.post(`/crud?${params}`, {});
  return response.data;
};

export const postAttendanceBanner = async (content: any) => {
  const params = jsonToUrlParams({_method: 'postAttendanceBanner'});
  const response = await restApi.post(`/crud?${params}`, content);
  console.log('response: ', response);
  return response.data;
};

export const putAttendanceBanner = async (_id: string, content: any) => {
  const params = jsonToUrlParams({_method: 'putAttendanceBanner', _id: _id});
  const response = await restApi.post(`/crud?${params}`, content);
  console.log('response: ', response);
  return response.data;
};
